export const sortingRuleForObject = (
  getItem = (item) => item.name,
  sortDirection = 'asc'
) => {
  const sortByAsc = (currentItem, nextItem) =>
    getItem(currentItem) > getItem(nextItem);
  const sortByDesc = (currentItem, nextItem) =>
    getItem(currentItem) < getItem(nextItem);

  const compareItem = sortDirection === 'asc' ? sortByAsc : sortByDesc;

  return (currentItem, nextItem) =>
    compareItem(currentItem, nextItem)
      ? 1
      : getItem(nextItem) === getItem(currentItem)
      ? 0
      : -1;
};
