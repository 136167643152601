export const scoresByMetrics = {
  fcp: {
    high: 945,
    average: 1600,
  },
  si: {
    high: 1327,
    average: 2300,
  },
  lcp: {
    high: 1200,
    average: 2416,
  },
  ti: {
    high: 2500,
    average: 4526,
  },
  tbt: {
    high: 152,
    average: 352,
  },
  cls: {
    high: 0.10,
    average: 0.25,
  },
};

export const getMetricColor = (type, value) => {
  const { high, average } = scoresByMetrics[type];

  return value <= high ? 'green' : value <= average ? 'orange' : 'red';
};
