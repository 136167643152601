import Vue from 'vue'
import VueHead from 'vue-head'
import VCalendar from 'v-calendar';

import App from './App.vue';
import router from './router';

Vue.config.productionTip = false

Vue.use(VueHead);
Vue.use(VCalendar);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
