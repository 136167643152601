<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  head: {
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "noindex,nofollow" },
    ],
    // script: [
    //   {
    //     type: "text/javascript",
    //     inner: `!function (t, e, c, n) { var s = e.createElement(c); s.async = 1, s.src = 'https://scripts.claspo.io/scripts/' + n + '.js'; var r = e.scripts[0]; r.parentNode.insertBefore(s, r); var f = function () { f.c(arguments); }; f.q = []; f.c = function () { f.q.push(arguments); }; t['claspo'] = t['claspo'] || f; }(window, document, 'script', '3C140EC916EB4C329B234C4B28539FA9');claspo('init');`,
    //     async: true,
    //     body: true,
    //   },
    // ],
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
