<template>
  <div class="form-container">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="urls">URLs (separated by newline):</label>
        <textarea id="urls" v-model="formData.urls" required v-auto-resize :placeholder="`https://example.com/
https://example2.com/
        `"></textarea>
      </div>
      <div class="checkbox-group">
        <input type="checkbox" id="mobile" v-model="formData.mobile" />
        <label for="mobile">Mobile</label>
      </div>
      <div class="checkbox-group">
        <input type="checkbox" id="desktop" v-model="formData.desktop" />
        <label for="desktop">Desktop</label>
      </div>
      <button type="submit" class="submit-button" :disabled="isSubmitting || !isValidateForm">Add pages</button>
    </form>
  </div>
</template>

<script>

import { db } from "../services/firebase"; // Assuming db is exported from firebase.js
import { writeBatch, collection, doc } from "firebase/firestore";

export default {
  name: 'addPage',
  data() {
    return {
      formData: {
        urls: '',
        mobile: true, // Selected by default
        desktop: true, // Selected by default
      },
      isSubmitting: false,
    };
  },
  computed: {
    isValidateForm() {
      return (this.formData.mobile || this.formData.desktop) && this.formData.urls;
    }
  },
  directives: {
    'auto-resize': {
      inserted(el) {
        el.style.overflow = 'hidden';
        el.style.resize = 'none';
        const resize = () => {
          el.style.height = 'auto';
          el.style.height = el.scrollHeight + 'px';
        };
        el.__resizeEventHandler__ = resize; // Store the resize function for later removal
        el.addEventListener('input', resize);
        resize();
      },
      unbind(el) {
        if (el.__resizeEventHandler__) {
          el.removeEventListener('input', el.__resizeEventHandler__); // Use the stored function to remove the event listener
        }
      }
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.formData.desktop && !this.formData.mobile) return;

      const urls = this.formData.urls.split("\n").filter(url => url.trim() !== ""); // Split URLs by newline and filter out any empty lines
      const batch = writeBatch(db); // Create a new batch

      urls.forEach((url) => {
        this.isSubmitting = true;
        if (this.formData.desktop) {
          const pageRef = doc(collection(db, "pages")); // Automatically generate a new doc ID
          batch.set(pageRef, {
            active: true,
            description: `DESKTOP ${url.trim()}`,
            projectId: "6Xjk5SNsKWBDDMy2Ej6y",
            url: url.trim(),
            strategy: "DESKTOP",
          });
        }

        if (this.formData.mobile) {
          const pageRef = doc(collection(db, "pages"));
          batch.set(pageRef, {
            active: true,
            description: `MOBILE ${url.trim()}`,
            projectId: "6Xjk5SNsKWBDDMy2Ej6y",
            url: url.trim(),
            strategy: "MOBILE",
          });
        }
      });

      batch.commit()
        .then(() => {
          alert("All pages added successfully");
        })
        .catch((error) => {
          alert("Error adding pages: ", error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-group {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.submit-button[disabled] {
  background-color: #c0c0c0;
}
</style>
