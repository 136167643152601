<template>
  <div>
    <button
      v-if="!userName"
      className="login__btn login__google"
      @click="signIn"
    >
      Login with Google
    </button>
    <span v-if="userName">{{ userName }}</span>
    <button
      v-if="userName"
      className="login__btn login__google"
      @click="logOut"
    >
      Log out
    </button>
    <router-view name="nav" />
    <router-view class="app-content" name="main" />
  </div>
</template>

<script>
import { db, logout, signInWithGoogle } from "../services/firebase";
import { query, getDocs, collection, where } from "firebase/firestore";

export default {
  name: "Base",
  data() {
    return {
      userName: "", // Initialize userName as an empty string
    };
  },
  async created() {
    await this.fetchUserName();
  },
  methods: {
    async fetchUserName() {
      const uid = localStorage.getItem("uid");
      if (!uid) return;

      const q = query(
        collection(db, "users"),
        where("uid", "==", localStorage.getItem("uid"))
      );
      const docs = await getDocs(q);
      if (docs.docs.length !== 0) {
        this.userName = docs.docs[0].data().name;
      }
    },
    async signIn() {
      await signInWithGoogle();
      window.location.reload();
    },
    logOut() {
      localStorage.removeItem("uid");
      logout();
      window.location.reload();
    },
  },
};
</script>
