<template>
  <tr :title="title">
    <td>{{ title }}</td>
    <td
      :class="[
        'min',
        getMetricColor(
          metricName,
          min(statistics, (x) => x[metricName])
        ),
      ]"
    >
      {{ normalizeValue(min(statistics, (x) => x[metricName])) }}
    </td>
    <td
      :class="[
        'avg',
        getMetricColor(
          metricName,
          avg(statistics, (x) => x[metricName])
        ),
      ]"
    >
      {{ normalizeValue(avg(statistics, (x) => x[metricName])) }}
    </td>
    <td
      :class="[
        'max',
        getMetricColor(
          metricName,
          max(statistics, (x) => x[metricName])
        ),
      ]"
    >
      {{ normalizeValue(max(statistics, (x) => x[metricName])) }}
    </td>
    <td
      :class="[getMetricColor(metricName, statistic[metricName])]"
      v-for="statistic in statistics"
      :key="statistic.date"
    >
      {{ normalizeValue(statistic[metricName]) }}
    </td>
  </tr>
</template>

<script>
import { min, avg, max } from "@/services/statisticsHelpers";
import { getMetricColor } from "@/services/metricsColourHelper";


export default {
  name: 'TR',
  props: {
    title: String,
    statistics: Array,
    metricName: String,
    normalizeValue: Function,
  },
  methods: {
    min,
    avg,
    max,
    getMetricColor,
  },
};
</script>

<style scoped>
tr,
td {
  border: 1px solid gray;
}

.min,
.avg,
.max {
  font-weight: bold;
}

.green {
  color: white;
  background: rgb(12, 206, 107);
}

.red {
  color: white;
  background: rgb(255, 78, 66);
}

.orange {
  background: rgb(255, 164, 0);
}
</style>
