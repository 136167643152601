<template>
  <div>
    <router-link :to="{ name: 'home' }">Home</router-link> | 
    <router-link :to="{ name: 'statistics' }">Statistics</router-link> | 
    <router-link :to="{ name: 'average' }">Average</router-link>
    <span v-if="isUserAuthenticated"> | 
      <router-link :to="{ name: 'add-page' }">Add page</router-link>
    </span>
    <!-- <router-link :to="{ name: 'jpeg-imgs' }">Jpeg images</router-link> | 
    <router-link :to="{ name: 'jpeg-imgs-with-lazy-load' }">Jpeg images with lazy load</router-link> | 
    <router-link :to="{ name: 'multiformat-imgs' }">Multiformat images</router-link> | 
    <router-link :to="{ name: 'multiformat-avif-imgs' }">Multiformat with avif images</router-link> | 
    <router-link :to="{ name: 'avif-imgs' }">Avif images</router-link> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  computed: {
    isUserAuthenticated() {
      return localStorage.getItem("uid");
    }
  }
};
</script>

<style lang="scss" scoped>
  .router-link-exact-active {
    text-decoration: none;
    color: black;
    cursor: default;
    font-weight: bold;
  }
</style>
