<template>
  <div class="average">
    <p>
      Filter by date:
      <v-date-picker
        class="v-date-picker"
        mode="range"
        v-model="range"
        :max-date="new Date()"
        :is-expanded="false"
        :attributes="datePickerAttrs"
        :columns="$screens({ default: 1, lg: 2 })"
      />
    </p>
    <p><strong>Last update</strong>: {{ lastUpdate | dateDefaultFormat }}</p>
    <table>
      <tr>
        <th>Page title</th>
        <th>Min Score</th>
        <th>AVG Score</th>
        <th>MAX Score</th>
        <th>Info</th>
      </tr>
      <tr v-if="loading">
        <td colspan="5" class="loading">LOADING...</td>
      </tr>
      <tr v-show="!loading" v-for="statistic in statistics" :key="statistic.id">
        <td>
          <a
            v-if="statistic.url !== 'https://sendpulse.ua/'"
            target="_blank"
            :href="statistic.url"
            :title="statistic.url"
            :disabled="true"
            >{{ statistic.description }}</a
          >
          <template v-else
            ><span :title="statistic.url">{{
              statistic.description
            }}</span></template
          >
        </td>
        <td :class="['min', getPerformanceColor(statistic.min)]">
          {{ statistic.min | toFixed }}
        </td>
        <td :class="['avg', getPerformanceColor(statistic.avg)]">
          {{ statistic.avg | toFixed }}
        </td>
        <td :class="['max', getPerformanceColor(statistic.max)]">
          {{ statistic.max | toFixed }}
        </td>
        <td>
          <router-link
            :to="{
              name: 'statisticsByPageId',
              params: { pageId: statistic.id },
            }"
            >Detailed statistics</router-link
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "Average",
  data() {
    return {
      loading: false,
      datePickerAttrs: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
      range: {
        start: new Date(moment().subtract(1, "week")),
        end: new Date(),
      },
      lastUpdate: "",
      statistics: [],
    };
  },
  async created() {
    await this.getAvgStatistics();
  },
  watch: {
    range: {
      deep: true,
      handler() {
        this.getAvgStatistics();
      },
    },
  },
  methods: {
    async getAvgStatistics() {
      const endpoint =
        "https://us-central1-pagespeed-watcher.cloudfunctions.net/widgets/api/v1/avg-statistics";

      if (this.range?.start && this.range?.end) {
        try {
          this.loading = true;
          const url = `${endpoint}?startAt=${moment(this.range.start)
            .startOf("day")
            .utc()
            .format()}&endAt=${moment(this.range.end)
            .endOf("day")
            .utc()
            .format()}`;

          const { data: { data, date } = {} } = await axios.get(url);

          this.lastUpdate = date;
          this.statistics = data;
        } catch (e) {
          this.statistics = [];
        } finally {
          this.loading = false;
        }
      }
    },
    getPerformanceColor(value) {
      if (value === null) return '';
      return value < 50 ? "red" : value < 90 ? "orange" : "green";
    },
  },
  filters: {
    dateDefaultFormat(value) {
      return moment.utc(value).local().format("DD/MM/YYYY HH:mm");
    },
    toFixed(value) {
      try {
        return value.toFixed(2);
      } catch (e) {
        return '-';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.average {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-date-picker /deep/ {
  .vc-w-full {
    width: auto;
  }
}

table {
  cursor: default;
}

a {
  text-decoration: none;
  padding: 0 7px;
}

table,
tr,
td,
th {
  border: 1px solid gray;
}
.min,
.avg,
.max {
  font-weight: bold;
}

.green {
  color: white;
  background: rgb(12, 206, 107);
}

.red {
  color: white;
  background: rgb(255, 78, 66);
}

.orange {
  background: rgb(255, 164, 0);
}

.loading {
  text-align: center;
  font-weight: bold;
}
</style>