var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{attrs:{"title":_vm.title}},[_c('td',[_vm._v(_vm._s(_vm.title))]),_c('td',{class:[
      'min',
      _vm.getMetricColor(
        _vm.metricName,
        _vm.min(_vm.statistics, (x) => x[_vm.metricName])
      ),
    ]},[_vm._v(" "+_vm._s(_vm.normalizeValue(_vm.min(_vm.statistics, (x) => x[_vm.metricName])))+" ")]),_c('td',{class:[
      'avg',
      _vm.getMetricColor(
        _vm.metricName,
        _vm.avg(_vm.statistics, (x) => x[_vm.metricName])
      ),
    ]},[_vm._v(" "+_vm._s(_vm.normalizeValue(_vm.avg(_vm.statistics, (x) => x[_vm.metricName])))+" ")]),_c('td',{class:[
      'max',
      _vm.getMetricColor(
        _vm.metricName,
        _vm.max(_vm.statistics, (x) => x[_vm.metricName])
      ),
    ]},[_vm._v(" "+_vm._s(_vm.normalizeValue(_vm.max(_vm.statistics, (x) => x[_vm.metricName])))+" ")]),_vm._l((_vm.statistics),function(statistic){return _c('td',{key:statistic.date,class:[_vm.getMetricColor(_vm.metricName, statistic[_vm.metricName])]},[_vm._v(" "+_vm._s(_vm.normalizeValue(statistic[_vm.metricName]))+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }