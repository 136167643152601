<template>
  <div class="hello">
    <p>
      Select page:
      <select v-model="selectedPageID">
        <option
          v-for="page in pages"
          :key="page.id"
          :value="page.id"
          :default="pageUrl === page.url"
        >
          {{ page.active ? "*" : "" }}
          {{ page.description }}
        </option>
      </select>
    </p>
    <p v-if="numberOfTests">Number of tests: {{ numberOfTests }}</p>
    <p v-if="pageUrl && pageUrl !== 'https://sendpulse.ua/'">
      Page address: <a :href="pageUrl" target="_blank">{{ pageUrl }}</a>
    </p>
    <p>
      Filter by date:
      <v-date-picker
        class="v-date-picker"
        mode="range"
        v-model="range"
        :max-date="new Date()"
        :is-expanded="false"
        :attributes="datePickerAttrs"
        :columns="$screens({ default: 1, lg: 2 })"
      />
    </p>
    <table>
      <tr>
        <td></td>
        <td>MIN</td>
        <td>AVG</td>
        <td>MAX</td>
        <td :colspan="numberOfTests"></td>
      </tr>
      <tr title="Score">
        <td>Score</td>
        <td :class="['min', minPerformanceColor]">
          {{ minPerformance }}
        </td>
        <td :class="['avg', avgPerformanceColor]">
          {{ avgPerformance }}
        </td>
        <td :class="['max', maxPerformanceColor]">
          {{ maxPerformance }}
        </td>
        <td
          :class="[getPerformanceColor(statistic.score * 100)]"
          v-for="statistic in statistics"
          :key="statistic.date"
        >
          {{ (statistic.score * 100).toFixed(2) }}
        </td>
      </tr>
      <TR
        title="First Contentful Paint"
        :statistics="statistics"
        metricName="fcp"
        :normalizeValue="(x) => `${(x / 1000).toFixed(2)}s`"
      />
      <TR
        title="Speed Index"
        :statistics="statistics"
        metricName="si"
        :normalizeValue="(x) => `${(x / 1000).toFixed(2)}s`"
      />
      <TR
        title="Largest Contentful Paint"
        :statistics="statistics"
        metricName="lcp"
        :normalizeValue="(x) => `${(x / 1000).toFixed(2)}s`"
      />
      <TR
        title="Time to Interactive"
        :statistics="statistics"
        metricName="ti"
        :normalizeValue="(x) => `${(x / 1000).toFixed(2)}s`"
      />
      <TR
        title="Total Blocking Time"
        :statistics="statistics"
        metricName="tbt"
        :normalizeValue="(x) => `${x.toFixed(2)}ms`"
      />
      <TR
        title="Cumulative Layout Shift"
        :statistics="statistics"
        metricName="cls"
        :normalizeValue="(x) => x.toFixed(3)"
      />
      <tr>
        <td>Date</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td
          v-for="statistic in statistics"
          :key="statistic.date"
          :title="statistic.date | dateDefaultFormat"
        >
          {{ statistic.date | dateMMDDHHmmFormat }}
        </td>
      </tr>
      <tr>
        <td>Lighthouse Version</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td
          v-for="statistic in statistics"
          :key="statistic.lighthouseVersion"
          title="Lighthouse Version"
        >
          {{ statistic.lighthouseVersion }}
        </td>
      </tr>
      <tr>
        <td>Chrome Version</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td
          v-for="statistic in statistics"
          :key="statistic.chromeVersion"
          title="Chrome Version"
        >
          {{ statistic.chromeVersion }}
        </td>
      </tr>
      <tr>
        <td>Strategy</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td
          v-for="statistic in statistics"
          :key="statistic.strategy"
          title="Strategy"
        >
          {{ statistic.strategy || "DESKTOP" }}
        </td>
      </tr>
    </table>
    <Chart :chartdata="chartdata" :options="chartOptions" :height="700"></Chart>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

import TR from "@/components/TR";
import Chart from "@/components/Chart.vue";

import { min, avg, max } from "@/services/statisticsHelpers";
import { getMetricColor } from "@/services/metricsColourHelper";
import { sortingRuleForObject } from "@/services/arrayHelpers";

export default {
  name: "HelloWorld",
  components: {
    TR,
    Chart,
  },
  props: {
    msg: String,
  },
  async created() {
    await this.getPages();
  },
  watch: {
    selectedPageID() {
      this.statistics = [];
      this.getStatistics(this.selectedPageID);
    },
    range: {
      deep: true,
      handler() {
        this.getStatistics(this.selectedPageID);
      },
    },
  },
  data() {
    return {
      datePickerAttrs: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
      range: {
        start: new Date(moment().subtract(1, "week")),
        end: new Date(),
      },
      selectedPageID: null,
      pageUrl: "",
      pages: [],
      statistics: [],
      statisticsChart: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Score",
              },
              ticks: {
                beginAtZero: true,
                max: 100,
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    numberOfTests() {
      return this.statistics.length;
    },
    avgPerformance() {
      return (this.avg(this.statistics, (x) => x.score) * 100).toFixed(2);
    },
    avgPerformanceColor() {
      return this.getPerformanceColor(this.avgPerformance);
    },
    minPerformance() {
      return this.min(this.statistics, (x) => x.score * 100).toFixed(2);
    },
    minPerformanceColor() {
      return this.getPerformanceColor(this.minPerformance);
    },
    maxPerformance() {
      return this.max(this.statistics, (x) => x.score * 100).toFixed(2);
    },
    maxPerformanceColor() {
      return this.getPerformanceColor(this.maxPerformance);
    },
    chartdata() {
      return {
        labels: this.statistics.map((statistic) =>
          moment.utc(statistic.date).local().format("DD.MM HH:mm")
        ),
        datasets: [
          {
            label: "Score",
            fill: false,
            backgroundColor: "#f87979",
            borderColor: "#f87979",
            data: this.statistics.map((statistic) => statistic.score * 100),
          },
        ],
      };
    },
  },
  methods: {
    getPerformanceColor(value) {
      return value < 50 ? "red" : value < 90 ? "orange" : "green";
    },
    getMetricColor,
    async getPages() {
      const endpoint =
        "https://us-central1-pagespeed-watcher.cloudfunctions.net/widgets/api/v1/pages";

      const { data } = await axios.get(endpoint);
      this.selectedPageID =
        this.$route.params.pageId ||
        (data.find((page) => page.active) || {}).id ||
        null;
      this.pages = data.sort(sortingRuleForObject((item) => item.description));
    },
    async getStatistics(pageId) {
      const endpoint =
        "https://us-central1-pagespeed-watcher.cloudfunctions.net/widgets/api/v1/page/";

      const url =
        this.range?.start && this.range?.end
          ? `${endpoint}${pageId}?startAt=${moment(this.range.start)
              .utc()
              .format()}&endAt=${moment(this.range.end)
              .endOf("day")
              .utc()
              .format()}`
          : `${endpoint}${pageId}`;

      const { data: { url: pageUrl, statistics = [] } = {} } = await axios.get(
        url
      );

      this.pageUrl = pageUrl;
      this.statistics = statistics;
    },
    avg,
    min,
    max,
    handleUpdateDatePicker(d) {
      console.log("asdasd", d);
    },
  },
  filters: {
    dateDefaultFormat: function (value) {
      return moment.utc(value).local().format("DD.MM.YYYY HH:mm");
    },
    dateMMDDHHmmFormat: function (value) {
      return moment.utc(value).local().format("DD.MM HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-date-picker /deep/ {
  .vc-w-full {
    width: auto;
  }
}

table {
  cursor: default;
}

table,
tr,
td {
  border: 1px solid gray;
}
.min,
.avg,
.max {
  font-weight: bold;
}

.green {
  color: white;
  background: rgb(12, 206, 107);
}

.red {
  color: white;
  background: rgb(255, 78, 66);
}

.orange {
  background: rgb(255, 164, 0);
}
</style>
