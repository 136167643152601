export const min = (arr, getItem = (x) => x) => {
  return Math.min(...arr.map(getItem));
};

export const max = (arr, getItem = (x) => x) => {
  return Math.max(...arr.map(getItem));
};

export const sumOfNumbers = (arr, getItem = (x) => x) => {
  return arr.reduce((acc, obj) => acc + getItem(obj), 0);
};

export const avg = (arr, getItem = (x) => x) => {
  return sumOfNumbers(arr, getItem) / arr.length;
};
