import Vue from 'vue';
import Router from 'vue-router';

import Base from './components/Base';
import Home from './components/Home';
import HelloWorld from './components/HelloWorld';
import Average from './components/Average';
import AddPage from './components/AddPage';
// import JpegImgsWithLazyLoad from './components/JpegImgsWithLazyLoad';
// import MultiformatImgs from './components/MultiformatImgs';
// import MultiformatAvifImgs from './components/MultiformatAvifImgs';
// import AvifImgs from './components/AvifImgs';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: Base,
      children: [
        {
          path: '/',
          name: 'home',
          components: {
            nav: Home,
          },
        },
        {
          path: '/statistics',
          name: 'statistics',
          components: {
            nav: Home,
            main: HelloWorld,
          },
          children: [
            {
              path: 'page/:pageId?',
              name: 'statisticsByPageId',
              components: {
                nav: Home,
                main: HelloWorld,
              },
            },
          ]
        },
        {
          path: '/average',
          name: 'average',
          components: {
            nav: Home,
            main: Average,
          },
        },
        {
          path: '/add-page',
          name: 'add-page',
          components: {
            nav: Home,
            main: AddPage,
          },
          meta: {
            requiresAuth: true,
          }
        },
        // {
        //   path: '/jpeg-imgs-with-lazy-load',
        //   name: 'jpeg-imgs-with-lazy-load',
        //   components: {
        //     nav: Home,
        //     main: JpegImgsWithLazyLoad,
        //   },
        // },
        // {
        //   path: '/multiformat-imgs',
        //   name: 'multiformat-imgs',
        //   components: {
        //     nav: Home,
        //     main: MultiformatImgs,
        //   },
        // },
        // {
        //   path: '/multiformat-avif-imgs',
        //   name: 'multiformat-avif-imgs',
        //   components: {
        //     nav: Home,
        //     main: MultiformatAvifImgs,
        //   },
        // },
        // {
        //   path: '/avif-imgs',
        //   name: 'avif-imgs',
        //   components: {
        //     nav: Home,
        //     main: AvifImgs,
        //   },
        // },
      ],
    },
  ],
});

// Using a global beforeEach guard to check for authentication
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication by looking for a meta field
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem("uid")) {
      // User is not authenticated, redirect to a login page
      next({ path: '/' });
    } else {
      // User is authenticated, proceed to the route
      next();
    }
  } else {
    // Route does not require authentication, always allow
    next();
  }
});

export default router;
