<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    chartdata: Object,
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
      chartdata: {
      deep: true,
      handler() {
        this.renderChart(this.chartdata, this.options);
      },
    },
  }
};
</script>
